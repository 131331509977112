// src/hooks/useTrackPageviews.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with route path as page parameter
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};