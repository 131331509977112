import React from 'react';
import '../styles/Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface MenuItemProps {
  caption: string;
  path: string;
  onClose: () => void;
}

interface MenuProps {
  onClose: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ caption, path, onClose }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
    onClose();
  };
  
  return (
    <div className='menu-item' onClick={ handleClick }>
      {caption}
    </div>
  );
};

const Menu: React.FC<MenuProps> = ({ onClose }) => {
  return (
    <div className='menu-container'>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button className='icon' style={{ margin: 'unset' }} onClick={onClose}>
          <FontAwesomeIcon icon={['fas', 'x']} />
        </button>
      </div>
      <MenuItem caption='Home' path='/' onClose={ onClose } />
      <MenuItem caption='About' path='/about' onClose={ onClose } />
    </div>
  )
}

export default Menu