// src/context/SharedLayout.tsx
import React from 'react'
import '../styles/SharedLayout.css';
import Header from '../components/Header'
import { useOutlet } from 'react-router-dom';
import Footer from '../components/Footer';
import { useTrackPageViews } from '../hooks/useTrackPageViews';

const SharedLayout = () => {
  const outlet = useOutlet();
  
  useTrackPageViews();

  return (
    <div className='layout-container'>
      <Header />
      <main className='main-container'>{outlet}</main>
      <Footer />
    </div>
  )
}

export default SharedLayout
