import React from 'react';
import Feature from '../components/Feature';

const AboutPage = () => {
  return (
    <>
      <Feature title='About Orbital Softworks' />
      <div className='page-content'>
        <p>Orbital Softworks emerged from years of undertaking private projects for diverse organizations. Our strength in problem-solving and product development now forms the foundation of Orbital Softworks.</p>
        <p>We strive to address the needs of small to medium businesses and enterprises by offering affordable and top-tier software-as-a-service (SaaS) solutions. Our goal is to provide high-quality services that effectively solve organizational challenges.</p>
        <p>Orbital Software is headquartered in Singapore.</p>
        <p><a href='https://www.linkedin.com/company/orbital-softworks' target='_blank' rel='noopener noreferrer'>Connect on LinkedIn</a></p>
      </div>
    </>
  );
};

export default AboutPage;