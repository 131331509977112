// src/components/Footer.tsx
import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='page-content footer'>
      <div className='footer-cell footer-left'>
        <span className='visible-on-laptop'>Copyright 2024 @ Orbital Softworks</span>
        <span className='visible-on-mobile'>Copyright 2024<br/>Orbital Softworks</span>
      </div>
      <div className='footer-cell footer-right'>
        <Link className='header' to='/privacypolicy'>Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Footer;