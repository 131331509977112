// src/components/Header.tsx
import React, { useState } from 'react';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './Menu';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  
  const navigateToLinkedIn = () => {
    window.open('https://www.linkedin.com/company/orbital-softworks', '_blank', 'noopener');
  };

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='header-container'>
      <div className='header-container-row'>
        <div style={{ flex: 1 }}>
          <Link to='/'>
            <img src={require('../images/osx horizontal logo.png')} alt="Home" />
          </Link>
        </div>
        <div className='header-options'>
          <div className='laptop-header-menu visible-on-laptop'>
            <Link className='header' to='/'>Home</Link>
            <Link className='header' to='/about'>About</Link>
          </div>
          <div>
            <button className='icon visible-on-mobile' onClick={openMenu}>
              <FontAwesomeIcon icon={['fas', 'bars']} />
            </button>
            <button className='icon' onClick={navigateToLinkedIn}>
              <FontAwesomeIcon icon={['fab', 'linkedin']} />
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && <Menu onClose={() => setIsMenuOpen(false)} />}
    </div>
  )
}

export default Header
