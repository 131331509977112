import React from 'react';
import Feature from '../components/Feature';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Feature title='Privacy Policy' />
      <div className='page-content'>
        <h1>Who we are</h1>
        <p>Our website address is: https://orbitalsoftworks.com.</p>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy describes how Orbital Softworks ("we", "us", or "our") collects, uses, and discloses your information when you use our website (the "Service").
        </p>
        <h2>Information We Collect</h2>
        <p>
          We collect information that you provide to us directly, such as when you fill out a contact form or sign up for our newsletter. This may include your name, email address, and any other information you choose to provide.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect to provide, maintain, and improve the Service, as well as to communicate with you about updates and new features. We may also use your information to respond to your inquiries or requests.
        </p>
        <h2>Information Sharing and Disclosure</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our website or conducting our business.
        </p>
        <h2>Security</h2>
        <p>
          We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will become effective immediately upon posting. We encourage you to review this Privacy Policy periodically for any updates or changes.
        </p>
        {/* <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy, please fill out our <a href="/contact">contact form</a>.
        </p> */}
      </div>
    </>
  );
};

export default PrivacyPolicyPage;