// src/pages/HomePage.tsx
import React, { useEffect, useRef, useState } from 'react'
import Feature from '../components/Feature';

const HomePage: React.FC = () => {
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(false);
  const iframeRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== 'https://tenorgames.com') return;

      const messageData = event.data;
      const iframe = document.getElementById('signupIframe') as HTMLIFrameElement | null;

      if (messageData.type === 'setHeight') {
        if (iframe) {
          iframe.style.height = `${messageData.height}px`;
        }
      } else if (messageData.type === 'formSubmitted') {
        // Adjust iframe height if necessary, or just focus on scrolling
        scrollToPageTop();
        setTimeout(() => {
          if (iframe) {
            iframe.style.height = `200px`;
          }
        }, 800);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const scrollToPageTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => {
      if (showSignUpForm && iframeRef.current) {
        const iframePosition = iframeRef.current.getBoundingClientRect();
        const scrollToPosition = iframePosition.top + window.pageYOffset - 100;
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
      }
    }, 800);
  }, [showSignUpForm]);
  
  return (
    <>
      <Feature title='Software applications for modern day small to medium businesses'>
        <p>Orbital Softworks has just launched! On our way to the first commercial SaaS product for small to medium sized businesses. Stay tuned!</p>
        <button onClick={() => setShowSignUpForm(true)}>Join The Mailing List</button>
        {showSignUpForm && (
          <div ref={iframeRef}>
            <iframe 
              title='Sign Up Form'
              id="signupIframe"
              width="540" 
              height="305" 
              src="https://tenorgames.com/orbitalsoftworks/SignUpForm.html" 
              frameBorder="0" 
              allowFullScreen // Corrected to camelCase
              style={{ // Corrected to an object with camelCased properties
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
                overflow: 'hidden',
              }}
            ></iframe>
          </div>
        )}
      </Feature>
    </>
  )
}

export default HomePage
