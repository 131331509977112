// src/components/Feature.tsx
import React from 'react'

interface FeatureProps {
  title: string;
  children?: React.ReactNode;
}

const Feature: React.FC<FeatureProps> = ({ title, children }) => {
  return (
    <div className='feature-container'>
      <div className='feature-content'>
        <div className='big-header'>{title}</div>
        {children ? <div className='feature-text'>{children}</div> : null}
      </div>
    </div>
  )
}

export default Feature